import * as React from "react"
import { Link } from "gatsby"
import Whitepaper from "../models/whitepaper"
import empowericon from "../../assets/images/icons/aboutarrow.svg"
import mobstrategy from "../../assets/images/mobile/strategy.jpg"

const Empower = ({
  heading,
  description,
  image,
  data,
  pageContext: { translate },
}) => {
  return (
    <>
      {
        <div className="strategy empower">
          <div className="content">
            <div className="intro">
              <h2 className="heading-h2 text-darker semi">{heading}</h2>
              <p className="paragraph-p1 text-medium">{description}</p>
            </div>
            <img src={image} className="mainclass desktop" alt="Strategy" />
            <img
              src={mobstrategy}
              className="mainclass mobile"
              alt="Strategy"
            />
            <div className="list">
              {data.map((content, count) => (
                <div key={count} className="list-box">
                  <h3 className="heading-h3 text-medium">{content.title}</h3>
                  <p className="paragraph-p3 text-medium">{content.content}</p>
                  {content.type === "download" && (
                    <Whitepaper
                      pageContext={{ translate }}
                      btnText={content.linktext}
                      styler="text-modal-btn"
                      title={translate.t?.download_the_whitepaper}
                      description={translate.t?.get_more_insights}
                      type="download"
                      submitBtnText={translate.t?.download_now}
                    />
                  )}
                  {!content.type && (
                    <Link
                      className="paragraph-p3-link paragraph-p3 a-animation bold"
                      to={content.link}
                    >
                      <span>
                        {content.linktext}{" "}
                        <img src={empowericon} alt="Empower" />
                      </span>
                    </Link>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Empower
