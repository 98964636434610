import * as React from "react"
import doter from "../../assets/images/icons/dots.svg"

export const Pillar = ({ data }) => {
  return (
    <div className="flex-container">
      {data.map((content, counter) => (
        <div key={counter} className="col-12 col-md-6 col-lg-4 box-wrapper">
          <div className="box">
            <div className="user">
              <img src={content.img} alt="Pills" />
              <div className="divider" />
              <div className="auth">
                <p className="paragraph-p16 semi text-medium">{content.name}</p>
                <p className="paragraph-p3 text-medium">
                  {content.designation}
                </p>
              </div>
            </div>
            <div className="relative-para">
              <img src={doter} alt="dots" />
              <p className="paragraph-p16 text-medium all">{content.quote}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

const Pills = ({
  dataRoaster,
  dataEstates,
  dataCustomers,
  dataFacilitators,
}) => {
  return (
    <div className="pills">
      <div className="content">
        <div className="tab-content">
          <Pillar data={[...dataRoaster]} />
        </div>
      </div>
    </div>
  )
}

export default Pills
