import * as React from "react"
import Link from "../link"
import empowericon from "../../assets/images/icons/aboutarrow.svg"

const Knowledge = ({ heading, description, image, data }) => {
  return (
    <>
      {
        <div className="strategy knowledge">
          <div className="content">
            <div className="flex-section jusitfy-content-start">
              <img src={image} alt="Knowledge" />
              <div className="intro">
                <h2 className="heading-h2 text-darker semi">{heading}</h2>
                <p className="paragraph-p1 text-medium">{description}</p>
              </div>
            </div>
            <div className="list">
              {data.map((content, count) => (
                <div key={count} className="list-box">
                  <h3 className="heading-h3 text-medium">{content.title}</h3>
                  <p className="paragraph-p3 text-medium">{content.content}</p>
                  <Link
                    className="paragraph-p3-link paragraph-p3 a-animation"
                    to={content.link}
                  >
                    <span>
                      {content.linktext} <img src={empowericon} alt="Empower" />
                    </span>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Knowledge
