import * as React from "react";
import Layout from "../components/layout/layout";
import SEO from "../components/layout/seo";
import SolutionsPage from "../components/solutions";
import languages from "../utils/enums/languages";

const Solutions = ({ location, pageContext: { translate } }) => (
  <Layout secondary pageContext={{ translate }} path="solutions" location={location} SEO={({ data }) =>
    <SEO
      title={data.t?.solution_for_everyone}
      description={data.t?.connect_with_farmers}
      keywords={data.t?.keywords_solutions}
      languages={[...languages.map((l) => {
        return {
          hrefLang: l || 'en',
          href: `${process.env.GATSBY_SITE_URL}${l ? '/' + l + '/' : '/'}solutions/`
        }
      }), {
        hrefLang: 'x-default',
        href: `${process.env.GATSBY_SITE_URL}/solutions/`
      }]}
    />
  }>
    <>
      {
        (
          <>
            <SolutionsPage pageContext={{ translate }} />
          </>
        )
      }
    </>
  </Layout>
)

export default Solutions
