import { Link } from "gatsby";
import * as React from "react";
import empowericon from "../../assets/images/icons/aboutarrow.svg";
import { Pillar } from "../../components/communitymain/pills";
const Partners = ({ heading, data, btnText, btnLink }) => {
  return (
  <>
    {
      (

    <div className="partner">
      <div className="content">
        <h2 className="heading-h2 text-darker semi">{heading}</h2>
        {!!btnText && (
          <Link className="paragraph-p16 bold" to={btnLink}>
            <span>{btnText} </span>
            <img src={empowericon} alt="Empower" />
          </Link>
        )}
        <div className="list">
          <Pillar data={data} />
        </div>
      </div>
    </div>
  )
    }
    </>
  )
}

export default Partners
