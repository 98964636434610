import React, { useState } from "react";
import { Accordion, Card } from "react-bootstrap";
import accordiandown from "../../assets/images/icons/menu-arrow.svg";

const Questions = ({ heading, data }) => {
  const [open, setOpen] = useState()
  return (
  <>
    {
      (

    <div className="question">
      <div className="content">
        <h2 className="heading-h2 text-darker semi">{heading}</h2>
        <Accordion>
          {data.map((content, counter) => (
            <Card key={counter}>
              <Accordion.Toggle
                onClick={() => {
                  if (open === counter + 1) {
                    setOpen()
                  } else {
                    setOpen(counter + 1)
                  }
                }}
                as={Card.Header}
                eventKey={counter + 1}
                className="d-flex"
              >
                {open === counter + 1 ? (
                  <img src={accordiandown} className="iconDown" alt="Accordian Down" />
                ) : (
                  <img src={accordiandown} alt="Accordian Down" />
                )}
                <div>{content.question}</div>
              </Accordion.Toggle>
              <Accordion.Collapse eventKey={counter + 1}>
                <Card.Body>{content.answer}</Card.Body>
              </Accordion.Collapse>
            </Card>
          ))}
        </Accordion>
      </div>
    </div>
  )
    }
    </>
  )
}

export default Questions
