import * as React from "react"

import Hero from "../roaster/hero"
import Empower from "./empower"
import Strategy from "./strategy"
import Knowledge from "./knowledge"
import Business from "./business"
import Partners from "./partners"
import Join from "../roaster/join"
import Questions from "./questions"
import Fazenda from "../../assets/images/Fazenda_California.webp"
import Luiz from "../../assets/images/Ama_Luiz.webp"
import Fredrik from "../../assets/images/Fredrik_Lo_fbergs.webp"
import empower from "../../assets/images/empower.png"
import strategy from "../../assets/images/strategy.jpg"
import knowledge from "../../assets/images/knowledge.png"

const Solutions = ({ pageContext: { translate } }) => {
  const data = [
    {
      title: translate.t?.estates,
      content: translate.t?.solution_estates,
      link: "/estates/",
    },
    {
      title: translate.t?.roasters,
      content: translate.t?.solution_roasters,
      link: "/coffee-roaster/",
    },
    {
      title: translate.t?.facilitators,
      content: translate.t?.solution_fc,
      link: "/facilitator/",
    },
    {
      title: translate.t?.specialty_roasters,
      content: translate.t?.solution_mr,
      link: "/micro-roaster/",
    },
    {
      title: translate.t?.hospitality,
      content: translate.t?.solution_hospitality,
      link: "/hospitality/",
    },
    {
      title: translate.t?.consumers,
      content: translate.t?.solution_consumers,
      link: "/consumer/",
    },
  ]
  const dataStrategy = [
    {
      title: translate.t?.sustainable_practices,
      content: translate.t?.eraofwe_ethical_code,
      link: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }sustainability/`,
      linktext: translate.t?.our_sustainability_vision,
    },
    {
      title: translate.t?.our_partner_program,
      content: translate.t?.eraofwe_partners_lsre,
      type: "download",
      linktext: translate.t?.our_guidelines,
    },
    {
      title: translate.t?.exp_as_a_service,
      content: translate.t?.interactive_media,
      link: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }traceability/`,
      linktext: translate.t?.how_traceability_works,
    },
  ]
  const dataKnowledge = [
    {
      title: translate.t?.partnership_community_culture,
      content: translate.t?.comm_shared_purpose,
      link: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }aboutus/`,
      linktext: translate.t?.view_our_values,
    },
    {
      title: translate.t?.people_skills_dev,
      content: translate.t?.improve_quality_edu,
      link: `${process.env.GATSBY_SITE_URL}/coffee-lab/${
        translate.currentLang === "sv"
          ? "sv/fragor-och-svar"
          : (translate.currentLang ? translate.currentLang : "en") + "/qa-forum"
      }`,
      linktext: translate.t?.our_coffee_lab,
    },
    {
      title: translate.t?.open_collab_innovation,
      content: translate.t?.encourage_innovation_collab,
      link: `${
        translate.currentLang ? "/" + translate.currentLang + "/" : "/"
      }community/`,
      linktext: translate.t?.our_community,
    },
  ]
  const dataPartner = [
    {
      img: Fazenda,
      name: "Flavia and Luiz Saldanha Rodrigues",
      designation: "Fazenda California",
      quote: translate.t?.public_quote_1,
    },
    {
      img: Luiz,
      name: "Luiz Paulo Dias Pereira Filho",
      designation: "Ama Coffee Estate",
      quote: translate.t?.tbh_eraofwe_newera,
    },
    {
      img: Fredrik,
      name: "Fredrik Nilsson",
      designation: "CEO - Löfbergs",
      quote: translate.t?.public_quote_3,
    },
  ]

  const dataquestion = [
    {
      question: translate.t?.what_is_eraofwe,
      answer: translate.t?.faq_1,
    },
    {
      question: translate.t?.who_can_use,
      answer: translate.t?.faq_2,
    },
    {
      question: translate.t?.who_benefits,
      answer: translate.t?.faq_3,
    },
    {
      question: translate.t?.can_anyone_access,
      answer: translate.t?.faq_4,
    },
    {
      question: translate.t?.does_eraofwe_subscription,
      answer: translate.t?.faq_5,
    },
  ]
  return (
    <>
      {
        <div className="solution_page">
          <div className="content">
            <div className="roaster_page">
              <Hero
                pageContext={{ translate }}
                page = "solution_section"
                pagename=""
                title={translate.t?.solution_for_everyone}
                description={translate.t?.connect_with_farmers}
                btntext={translate.t?.book_a_demo}
                btnLink=""
                model="contact"
              />
            </div>
            {/* <Empower
             pageContext={{ translate }}
              heading={translate.t?.empower_team}
              description={translate.t?.our_platform_ultimate_tool}
              image={empower}
              data={data}
            /> */}
            <Strategy
              pageContext={{ translate }}
              heading={translate.t?.eraofwe_features_shared_success}
              description={translate.t?.team_stronger_empowering}
              image={strategy}
              data={dataStrategy}
            />
            <Knowledge
              heading={translate.t?.our_community_empowerment_knowledge}
              description={translate.t?.education_strengthens}
              image={knowledge}
              data={dataKnowledge}
            />
            {/* <Business
              btnText={translate.t?.our_mission}
              description={translate.t?.host_coffee_growers}
              btnlink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }aboutus/`}
              title={translate.t?.shift_optimistic_capitalism}
            /> */}
            <Partners
              heading={translate.t?.achieving_great_things}
              data={dataPartner}
              btnText={translate.t?.view_our_community}
              btnLink={`${
                translate.currentLang ? "/" + translate.currentLang + "/" : "/"
              }community/`}
            />
            <Questions heading={translate.t?.faq} data={dataquestion} />
            <div className="joiner-second">
              <Join pageContext={{ translate }} />
            </div>
          </div>
        </div>
      }
    </>
  )
}

export default Solutions
