import * as React from "react"
import Link from "../link"
import Whitepaper from "../models/whitepaper"
const Hero = ({
  page,
  pagename,
  title,
  subTitle,
  description,
  btntext,
  btnLink,
  img,
  model,
  img1,
  img2,
  pageContext: { translate },
}) => {
  const handleButtonClick = () => {
    window.lintrk("track", { conversion_id: 14776012 })
    window.location.href = btnLink
  }
  return (
    <>
      {
        <div className="hero">
          <div className="content">
            {img1 && (
              <>
                <img src={img1} className="bgimg1" alt="Hero Background" />
                <img src={img2} className="bgimg2" alt="Hero Background_2" />
              </>
            )}
            <div className="information">
              {pagename && <h2>{pagename}</h2>}
              {title && <h1>{title}</h1>}
              {subTitle && <h2 className="title">{subTitle}</h2>}

              {<p>{description}</p>}

              {page === "solution_section" && (
                <div>
                  <ol>
                    <li className="text-left solution-desp">
                      {translate.t?.automize_eudr_information}
                    </li>
                    <li className="text-left solution-desp">
                      {translate.t?.build_your_own_coffee_traceability}
                    </li>
                    <li className="text-left solution-desp">
                      {translate.t?.gather_your_eudr_traceability_risk_info}
                    </li>
                    <li className="text-left solution-desp">
                      {
                        translate.t
                          ?.monitor_and_share_product_batch_specific_policy
                      }
                    </li>
                  </ol>
                </div>
              )}
              {btntext &&
                (model === "contact" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btntext}
                    styler="greenbtn"
                    title={translate.t?.contact_form}
                    description={translate.t?.get_in_contact}
                    type="contact"
                    submitBtnText={translate.t?.submit}
                  />
                ) : model === "download" ? (
                  <Whitepaper
                    pageContext={{ translate }}
                    btnText={btntext}
                    styler="greenbtn"
                    title={translate.t?.download_the_whitepaper}
                    description={translate.t?.get_more_insights}
                    type="download"
                    submitBtnText={translate.t?.submit}
                  />
                ) : (
                  <Link target="_blank" to={btnLink}>
                    <button
                      className="btndefault greenbtn"
                      onClick={handleButtonClick}
                    >
                      {btntext}
                    </button>
                  </Link>
                ))}
            </div>
            {img && <img src={img} alt="Hero" />}
          </div>
        </div>
      }
    </>
  )
}

export default Hero
