import * as React from "react"
import Whitepaper from "../models/whitepaper"
const Join = ({ pageContext: { translate } }) => {
  return (
    <>
      {
        <div className="join global">
          <div className="content">
            <h5>{translate.t?.book_a_demo}</h5>
            <Whitepaper
              pageContext={{ translate }}
              btnText={translate.t?.book_a_demo}
              styler="greenbtn"
              title={translate.t?.contact_form}
              description={translate.t?.contact_form_description}
              type="contact"
              submitBtnText={translate.t?.submit}
            />
          </div>
        </div>
      }
    </>
  )
}

export default Join
