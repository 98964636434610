import { Link } from "gatsby";
import * as React from "react";

const Business = ({
  btnText,
  description,
  secondDescription,
  btnlink,
  title,
}) => {
  return (
  <>
    {
      (

    <div className="business">
      <div className="content">
        <h2 className="heading-h2 semi text-white">{title}</h2>
        <p className="paragraph-p1 text-white">
          {description}
          {secondDescription && (
            <span className="text-italic">{secondDescription}</span>
          )}
        </p>
        {!!btnText && (
          <Link to={btnlink}>
            <button className="btndefault whiteborderbtn">{btnText}</button>
          </Link>
        )}
      </div>
    </div>
  )
    }
    </>
  )
}

export default Business
